<template>
  <!-- General Information -->
  <div
    class="tab-pane fade show active"
    id="general-info"
    role="tabpanel"
    aria-labelledby="general-info-tab"
  >
    <!-- Tab Content -->
    <div class="row align-items-stretch mb-xl-3">
      <!-- MAIN CONTENT -->
      <div class="col-12 mb-3 col-xl mb-xl-0">
        <div class="card h-100">
          <div class="card-body">
            <h5 class="custom-card-title">
              <i class="fas fa-cube"></i>
              <span>ÜRÜN GENEL BİLGİLERİ</span>
            </h5>
            <hr />
            <!-- Product Name -->
            <WeInput
              label="Ürün Adı"
              name="product_name"
              placeholder="Ürün Adı"
              v-bind:required="true"
              v-model="productName"
              v-bind:error="$v.productName.$error"
              @input="onNameChange"
            />
            <!-- ./Product Name -->

            <!-- Slug -->
            <WeSlugCheck
              v-bind:value="product.info.slug"
              v-bind:id="$route.params.id"
              v-bind:slug="product.info.slug"
              v-bind:search="true"
              v-on:slugify="changeSlug"
              url-fragment="urun"
              type="product"
            />
            <!-- ./Slug -->

            <!-- Stock Code -->
            <WeInput
              label="Stok Kodu"
              name="stock_code"
              placeholder="Stok Kodu"
              v-bind:required="true"
              v-model="productSku"
              v-bind:error="$v.productSku.$error"
            />
            <!-- ./Stock Code -->

            <!-- Currency -->
            <div class="form-group">
              <div class="row">
                <div class="col">
                  <label
                    for="currency"
                    class="custom-label"
                    v-bind:class="{
                      'text-danger': $v.productCurrencyCode.$error,
                    }"
                    >Para Birimi</label
                  >
                </div>
                <div class="col-auto" v-if="$v.productCurrencyCode.$error">
                  <div
                    class="text-danger ml-auto"
                    v-if="!$v.productCurrencyCode.required"
                  >
                    <small><i>Zorunlu Alan</i></small>
                  </div>
                </div>
              </div>
              <select
                name="currency"
                id="currency"
                class="custom-select"
                :class="{
                  'border-danger': $v.productCurrencyCode.$error,
                }"
                v-model="productCurrencyCode"
                v-on:change="changeCurrencySymbol"
              >
                <option value="-1">Seçiniz</option>
                <option
                  v-for="currency in product.currencies"
                  v-bind:key="currency.id"
                  v-bind:value="currency.id"
                >
                  {{ currency.name }}
                </option>
              </select>
            </div>
            <!-- ./Currency -->

            <div class="form-row">
              <!-- Normal Price -->
              <div class="col-12 col-md">
                <WePriceInput
                  label="Satış Fiyatı"
                  name="normal_price"
                  v-bind:required="true"
                  v-model="productPrice"
                  v-bind:error="$v.productPrice.$error"
                  v-bind:append="product.currencySymbol"
                  v-bind:read-only="priceSetting"
                />
              </div>
              <!-- ./Normal Price -->

              <!-- Discounted Price -->
              <div class="col-12 col-md">
                <WePriceInput
                  label="İndirimli Fiyat"
                  name="discounted_price"
                  v-bind:required="true"
                  v-bind:max-value="productPrice.unmask"
                  v-model="productDiscount"
                  v-bind:append="product.currencySymbol"
                />
              </div>
              <!-- ./Discounted Price -->

              <!-- Tax Amount -->
              <div class="col-12 col-md">
                <WeInput
                  label="KDV"
                  name="tax-amount"
                  v-bind:prepend="'%'"
                  v-bind:required="true"
                  v-model="taxAmount"
                  v-bind:error="$v.taxAmount.$error"
                />
              </div>
              <!-- ./Tax Amount -->
            </div>

            <!-- Stock Information -->
            <div class="form-row mb-1">
              <!-- Stock Amount -->
              <div class="col-12 col-md">
                <WeInput
                  label="Stok"
                  name="stock"
                  v-bind:required="true"
                  v-model="productQty"
                  v-bind:error="$v.productQty.$error"
                />
              </div>
              <!-- ./Stock Amount -->

              <!-- Stock Type -->
              <div class="col-12 col-md">
                <div class="form-group">
                  <div class="row">
                    <div class="col">
                      <label
                        for="stock_type"
                        class="custom-label"
                        v-bind:class="{
                          'text-danger': $v.productStockType.$error,
                        }"
                        >Stok Tipi</label
                      >
                    </div>
                    <div class="col-auto" v-if="$v.productStockType.$error">
                      <div
                        class="text-danger ml-auto"
                        v-if="!$v.productStockType.required"
                      >
                        <small><i>Zorunlu Alan</i></small>
                      </div>
                    </div>
                  </div>
                  <select
                    name="stock_type"
                    id="stock_type"
                    class="custom-select"
                    :class="{
                      'border-danger': $v.productStockType.$error,
                    }"
                    v-model="productStockType"
                  >
                    <option value="-1">Seçiniz</option>
                    <option
                      v-for="option in product.stockTypeOptions"
                      v-bind:key="option.id"
                      v-bind:value="option.id"
                    >
                      {{ option.name }}
                    </option>
                  </select>
                </div>
              </div>
              <!-- ./Stock Type -->
            </div>
            <!-- ./Stock Information -->

            <!-- Product Setting -->
            <label class="custom-label mb-2">Ürün Durumu</label>
            <div class="row">
              <div class="col-12 col-md-auto">
                <WeSwitch
                  v-model="product.info.setting.is_active"
                  v-bind:show-status="true"
                  v-on:change="statusChange"
                />
              </div>
              <div class="col-12 col-md-auto">
                <WeSwitch
                  v-model="newSettingValue"
                  v-on:input="onChangeNewProduct"
                  label="Yeni Ürün"
                />
              </div>
              <div class="col-12 col-md-auto">
                <WeSwitch
                  v-model="product.info.setting.featured"
                  v-on:input="onChangeFeatured"
                  label="Öne Çıkar"
                />
              </div>
              <div class="col-12 col-md-auto">
                <WeSwitch
                  v-model="product.info.setting.is_store_product"
                  label="Mağaza Ürünü"
                />
              </div>
              <div class="col-12 col-md-auto">
                <WeSwitch
                  v-model="product.info.setting.sell_out_stock"
                  label="Stoksuz Satış"
                />
              </div>
              <div class="col-12 col-md-auto">
                <WeSwitch
                  v-model="product.info.setting.show_in_merchant_xml"
                  label="Google Merchant XML'de Görünsün"
                />
              </div>
              <div class="col-12 col-md-auto">
                <WeSwitch
                  v-model="product.info.setting.use_installment"
                  label="Taksit Seçenekleri"
                />
              </div>
              <div class="col-12 col-md-auto">
                <WeSwitch
                  v-model="product.info.setting.is_free_cargo"
                  label="Ücretsiz Kargo"
                />
              </div>
            </div>
            <!-- Product Setting -->
          </div>
        </div>
      </div>
      <div class="col-12 mb-3 col-xl-6 mb-xl-0">
        <!-- Category Section -->
        <WeCategory
          v-model="product.info.categories"
          v-bind:language="product.lang"
          v-bind:watch-language="!$route.params.id"
          v-bind:placeholder="$t('searchCategory')"
          v-bind:title="$filters.toUpperCase($t('categories'))"
        />
        <!-- ./Category Section -->
      </div>
    </div>
    <!-- ./Tab Content -->

    <div class="row align-items-start">
      <div
        v-bind:class="[
          'col-12 mb-3 mb-xl-0',
          marketplacePriceConfig ? 'col-xl-6' : 'col-xl',
        ]"
      >
        <!-- Product Images -->
        <WeImageUploader
          v-model="product.info.image"
          v-bind:fixedMetaTitle="productName"
          v-bind:fixedMetaAlt="productName"
          multiple="1"
          title="GÖRSEL YÜKLE"
        />
        <!-- ./Product Images -->
      </div>

      <div
        v-bind:class="[
          'col-12 mb-3 mb-xl-0',
          marketplacePriceConfig ? 'col-xl-6' : 'col-xl',
        ]"
      >
        <!-- Product Images -->
        <WeImageUploader
          v-model="product.info.mobile_image"
          v-bind:fixedMetaTitle="productName"
          v-bind:fixedMetaAlt="productName"
          multiple="1"
          title="MOBİL GÖRSEL YÜKLE"
        />
        <!-- ./Product Images -->
      </div>

      <div class="col-12 col-xl-6" v-if="marketplacePriceConfig">
        <WeMarketplacePrice
          class="mb-3"
          v-bind:product="product"
          v-model="product.info.marketplacePrices"
        />
      </div>
    </div>
  </div>
  <!-- ./General Information -->
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  name: "Information",
  data() {
    return {};
  },
  validations: {
    productName: {
      required,
    },
    productPrice: {
      required,
    },
    productQty: {
      required,
    },
    taxAmount: {
      required,
    },
    productStockType: {
      required: function () {
        return this.productStockType !== "-1" && this.productStockType !== -1;
      },
    },
    productCurrencyCode: {
      required: function () {
        return (
          this.productCurrencyCode !== "-1" && this.productCurrencyCode !== -1
        );
      },
    },
    productSku: {
      required,
    },
  },
  methods: {
    ...mapMutations("product", [
      "filterProductCategories",
      "changeCurrencySymbol",
      "removeSelectedImage",
    ]),
    checkActiveMarketplaces() {
      return this.product.detail.marketplaces.filter(
        (mp) => mp.market.is_active && mp.active
      ).length;
    },
    setNewSettingValue() {
      if (!this.$route.params.id) {
        this.newSettingValue = true;
      }
    },
    onNameChange(name) {
      if (!this.$route.params.id) {
        this.product.info.slug = name;
      }
    },
    changeSlug(data) {
      this.product.info.slug = data;
    },
    statusChange(data) {
      if (this.marketplaceConfig && this.checkActiveMarketplaces()) {
        if (!data) {
          this.$swal({
            title:
              "Uyarı: Ürün bağlı olduğu tüm pazaryerlerinde satışa kapatılacaktır!",
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: this.$t("cancel"),
          }).then((confirm) => {
            if (confirm.isConfirmed) {
              this.product.info.setting.is_active = false;
            } else {
              this.product.info.setting.is_active = true;
            }
          });
        } else {
          this.product.info.setting.is_active = true;
        }
      } else {
        this.product.info.setting.is_active = data;
      }
    },
    onChangeFeatured(e) {
      if (e) {
        this.newSettingValue = false;
      }
    },
    onChangeNewProduct(e) {
      if (e) {
        this.product.info.setting.featured = false;
      }
    },
  },
  computed: {
    ...mapState(["product", "session"]),
    readOnly() {
      if (this.$route.params.id) {
        return true;
      }

      return false;
    },
    marketplaceConfig() {
      return (
        this.session.config["site.marketplace_integration"] == "1" ||
        this.session.config["site.marketplace_integration"] == 1
      );
    },
    marketplacePriceConfig() {
      return (
        this.session.config["site.marketplace_price_integration"] == "1" ||
        this.session.config["site.marketplace_price_integration"] == 1
      );
    },
    productName: {
      get() {
        return this.product.info.name;
      },
      set(value) {
        this.product.info.name = value;
      },
    },
    productPrice() {
      return this.product.info.normalPrice;
    },
    productDiscount() {
      return this.product.info.discountedPrice;
    },
    productQty: {
      get() {
        return this.product.info.stock;
      },
      set(value) {
        this.product.info.stock = value;
        if (
          value <= 0 &&
          this.marketplaceConfig &&
          this.checkActiveMarketplaces()
        ) {
          this.$swal({
            title:
              "Uyarı: Ürün bağlı olduğu tüm pazaryerlerinde satışa kapatılacaktır!",
            icon: "warning",
            showCancelButton: false,
          });
        }
      },
    },
    productStockType: {
      get() {
        let stockType = this.product.info.selected.stockType;
        if (stockType == "-1" || (stockType == -1 && !this.$route.params.id)) {
          stockType = this.session.config["site.stock_type"];
        }

        return stockType;
      },
      set(value) {
        this.product.info.selected.stockType = value;
      },
    },
    productCurrencyCode: {
      get() {
        return this.product.info.selected.currency;
      },
      set(value) {
        this.product.info.selected.currency = value;
      },
    },
    productSku: {
      get() {
        return this.product.info.stockCode;
      },
      set(value) {
        this.product.info.stockCode = value;
      },
    },
    taxAmount: {
      get() {
        return this.product.info.taxAmount;
      },
      set(value) {
        this.product.info.taxAmount = value;
      },
    },
    newSettingValue: {
      get() {
        return this.product.info.setting.new;
      },
      set(value) {
        this.product.info.setting.new = value;
      },
    },
    priceSetting() {
      const setting = this.session.config["site.api_update_price"];
      const productApiCode = this.product.detail.productApiCode;

      return productApiCode?.length > 0 && (setting == "1" || setting == 1);
    },
  },
  mounted() {
    this.setNewSettingValue();
  },
  watch: {
    "product.info.categories": function (newVal) {
      if (newVal && newVal.length) {
        const selectedCategory = newVal[newVal.length - 1];
        this.taxAmount =
          selectedCategory.tax || this.session.config["site.vat"] || 0;
      } else {
        this.taxAmount = this.session.config["site.vat"];
      }
    },
  },
};
</script>
