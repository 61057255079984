<template>
  <WeCard>
    <!-- Card Title -->
    <h5 class="custom-card-title">
      <i class="fas fa-sync"></i><span>TOPLU GÜNCELLEME</span>
    </h5>
    <hr />
    <!-- ./Card Title -->

    <!-- Content -->
    <div class="row align-items-end">
      <!-- Feature Value -->
      <div class="col-12 mb-3 mb-lg-0 col-lg-auto">
        <label class="custom-label">Varyasyon Tipi</label>
        <v-select
          label="name"
          v-bind:clearable="false"
          placeholder="Varyasyon Tipi"
          style="min-width: 200px"
          v-bind:reduce="(r) => r.id"
          v-bind:options="modeOptions"
          v-model="mode"
        ></v-select>
      </div>
      <!-- ./Feature Value -->
      <!-- Feature Value -->
      <div class="col-12 mb-3 mb-lg-0 col-lg-auto pl-lg-0">
        <label class="custom-label">Özellik Değerleri</label>
        <v-select
          v-bind:options="getFeatureValues"
          v-bind:class="{ 'v-select--error': $v.selectedValues.$error }"
          label="name"
          v-bind:multiple="true"
          v-bind:close-on-select="false"
          placeholder="Özellik Değeri Seçin"
          v-model="selectedValues"
        ></v-select>
      </div>
      <!-- ./Feature Value -->

      <!-- Quantity -->
      <div class="col-12 col-lg pl-lg-0">
        <WeInput
          class="mb-3 mb-lg-0"
          label="Stok"
          v-bind:filter-number="true"
          v-bind:required="true"
          v-bind:error="$v.quantity.$error"
          v-model="quantity"
        />
      </div>
      <!-- ./Quantity -->

      <!-- Price -->
      <div class="col-12 col-lg pl-lg-0">
        <WePriceInput
          class="mb-3 mb-lg-0"
          label="Fiyat"
          v-bind:required="true"
          v-bind:error="$v.price.$error"
          v-model="price"
        />
      </div>
      <!-- ./Price -->

      <!-- Discounted Price -->
      <div class="col-12 col-lg pl-lg-0">
        <WePriceInput
          class="mb-3 mb-lg-0"
          label="İndirimli Fiyat"
          v-model="discounted_price"
        />
      </div>
      <!-- ./Discounted Price -->

      <div class="col-auto">
        <button class="btn btn-info" v-on:click="onSave">
          <i class="fas fa-sync"></i> Güncelle
        </button>
      </div>
    </div>
    <!-- ./Content -->
  </WeCard>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import { mapState } from "vuex";
export default {
  name: "BulkVariantUpdate",
  model: {
    prop: "variants",
  },
  validations: {
    price: {
      required,
    },
    quantity: {
      required,
    },
    selectedValues: {
      required,
    },
  },
  data() {
    return {
      mode: "current",
      modeOptions: [
        {
          id: "current",
          name: "Mevcut Varyasyonlar",
        },
        {
          id: "available",
          name: "Kullanılabilecek Varyasyonlar",
        },
      ],
      quantity: 0,
      price: helper.setPrice(0),
      discounted_price: helper.setPrice(0),
      selectedValues: [],
    };
  },
  methods: {
    validateForm() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.$swal({
          title: "Hata",
          text: "Lütfen zorunlu alanları doldurun",
          icon: "error",
        });
        return false;
      } else {
        return true;
      }
    },
    onSave() {
      if (!this.validateForm()) return;

      this.$swal({
        title: "İşlemi Onaylıyor Musunuz ?",
        text: "Özellik değerlerinin bulunduğu tüm varyasyonlar güncellenecek.",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "İptal",
        confirmButtonText: "Güncelle",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          this.updateVariants();
        }
      });
    },
    validateUpdate() {
      let result = true;

      switch (this.mode) {
        case "current":
          result = this.product.variants.length > 0;
          break;

        case "available":
          result = this.product.availableVariants.length > 0;
          break;
      }

      if (!result) {
        this.$swal({
          title: "Hatalı İşlem",
          text:
            (this.mode == "current" ? "Mevcut" : "Kullanılabilecek") +
            " Varyasyonlar mevcut değil",
          icon: "error",
        });
      }
      return result;
    },
    updateVariants() {
      if (!this.validateUpdate()) {
        return;
      }
      const selectedValueKeys = helper.arrayPluck(
        this.selectedValues,
        "feature_id"
      );
      const selectedValueNames = helper.arrayPluck(this.selectedValues, "name");
      const priceData = {
        price: this.price.unmask || this.price.price,
        unmask: this.price.unmask || this.price.price,
      };
      const discountPriceData = {
        price: this.discounted_price.unmask || this.discounted_price.price,
        unmask: this.discounted_price.unmask || this.discounted_price.price,
      };

      this.variants = this.variants.map((v) => {
        Object.entries(v).forEach(([key, value]) => {
          if (
            selectedValueKeys.includes(key) &&
            selectedValueNames.includes(value)
          ) {
            v.price = helper.clone(priceData);
            v.discount_price = helper.clone(discountPriceData);
            v.quantity = helper.clone(this.quantity);
          }
        });
        return v;
      });
      this.variants.sort();
    },
  },
  computed: {
    ...mapState(["product"]),
    getFeatureValues() {
      const result = [];
      if (this.product.variantFeatures.length) {
        const featureHasValue = this.product.variantFeatures.filter(
          (feature) => feature.hasOwnProperty("value") && feature.value
        );

        if (featureHasValue.length) {
          featureHasValue.forEach((item) => {
            switch (typeof item.value) {
              case "object":
                result.push(item.value);
                break;

              case "array":
                item.value.forEach((value) => result.push(value));
            }
          });
        }
      }

      return result.flat();
    },
    variants: {
      get() {
        let variants = this.product.variants;
        if (this.mode == "available") variants = this.product.availableVariants;
        return variants;
      },
      set(value) {
        if (this.mode == "available") {
          this.product.availableVariants = value;
        } else {
          this.product.variants = value;
        }
      },
    },
  },
};
</script>