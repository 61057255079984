<template>
  <WeModal v-if="show" v-on:close="closeModal" v-bind:title="getModalTitle">
    <div slot="body">
      <table class="table table-bordered">
        <thead class="thead-light">
          <tr>
            <th scope="col">Ürün Kodu</th>
            <th scope="col">Marka</th>
            <th scope="col">İşlemler</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <input
                type="text"
                class="form-control"
                placeholder="Ürün Kodu"
                v-model="item.code"
                v-on:keyup.enter="addOrUpdate()"
                ref="code"
              />
            </td>
            <td>
              <input
                type="text"
                class="form-control"
                placeholder="Marka"
                v-model="item.name"
                v-on:keyup.enter="addOrUpdate()"
              />
            </td>
            <td class="text-center">
              <!-- Create -->
              <span
                class="btn btn-outline-success"
                v-on:click="addOrUpdate()"
                v-if="!this.crossproduct.item.update"
              >
                <i class="fas fa-plus"> </i>
              </span>
              <!-- Create -->

              <!-- Update -->
              <span
                class="btn btn-outline-success"
                v-on:click="addOrUpdate()"
                v-else
              >
                <i class="fas fa-check"> </i>
              </span>
              <!-- Update -->
            </td>
          </tr>
        </tbody>
      </table>

      <div v-if="crossList && crossList.length">
        <WeTable
          v-bind:data="crossList"
          v-bind:limitable="false"
          v-bind:columns="columns"
          v-bind:actions="actions"
          v-bind:itemsPerPage="5"
          v-on:on-action="onAction"
          v-bind:index="false"
        />
        <hr />
        <div class="text-center">
          <div class="btn btn-success" v-on:click="saveList">
            <span v-if="buttonReady"> <i class="fas fa-save"></i> Kaydet </span>
            <i class="fas fa-spinner fa-spin" v-else></i>
          </div>
        </div>
      </div>
    </div>
  </WeModal>
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "CrossProductModal",
  data() {
    return {
      buttonReady: true,
      columns: [
        { name: "code", th: "Ürün Kodu", type: "string" },
        { name: "name", th: "Marka", type: "string" },
      ],
      actions: [
        {
          icon: "fas fa-trash",
          class: "btn-outline-danger btn-sm border-0 ml-2",
          action: "remove",
        },
      ],

      crossProducts: null, // computedData
      crossList: [],
      item: {
        id: null,
        name: null,
        code: null,
        index: null,
      },
    };
  },
  props: {
    data: null,
    show: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapMutations("crossproduct", ["clearCrossItem"]),
    clearItem() {
      this.item = {
        id: null,
        name: null,
        code: null,
        index: null,
      };
    },
    addOrUpdate() {
      if (this.item.name && this.item.code) {
        if (this.item.update) {
          this.put();
        } else {
          this.add();
        }
      } else {
        this.$swal({
          title: "Hata",
          text: "Lütfen zorunlu alanları doldurun",
          icon: "error",
        });
      }
    },
    add() {
      this.crossList.push(this.item);
      this.clearItem();

      this.$refs.code.focus();
    },
    put() {
      const index = this.item.index;
      delete this.item.index;

      this.computedData.splice(index, 1);
      this.computedData.push(this.item);
      this.closeModal();
    },
    saveList() {
      this.buttonReady = false;
      setTimeout(() => {
        const list = this.crossList;
        if (list.length) {
          list.forEach((element) => {
            this.computedData.push(element);
          });
        }

        this.crossList = [];
        this.closeModal();
      }, 1000);
    },
    onAction(data) {
      if (data.key == "remove") {
        let message = "Silme işlemini onaylıyor musunuz ?";
        this.$swal({
          title: message,
          showCancelButton: true,
          cancelButtonText: "İptal",
          confirmButtonText: "Sil",
          icon: "warning",
        }).then((confirm) => {
          if (confirm.isConfirmed) {
            this.crossList.splice(data.index, 1);
          }
        });
      }
    },
    closeModal() {
      this.$emit("on-close");
      this.clearItem();
      this.buttonReady = true;
    },
  },
  model: {
    prop: "data",
  },
  computed: {
    ...mapState(["crossproduct"]),
    getModalTitle() {
      return this.crossproduct.item.update
        ? "Çapraz Ürün Düzenle"
        : "Çapraz Ürün Ekle";
    },
    computedData() {
      return this.data;
    },
  },
  watch: {
    show: function(newVal, oldVal) {
      if (newVal === true && this.crossproduct.item.update) {
        this.item = this.crossproduct.item;
      } else {
        this.clearCrossItem();
      }
    },
  },
};
</script>
