<template>
  <!-- Product Detail -->
  <div
    class="tab-pane fade"
    id="product-detail"
    role="tabpanel"
    aria-labelledby="product-detail-tab"
  >
    <!-- Tab Content -->
    <div class="row">
      <!-- MAIN CONTENT -->
      <div class="col-12 mb-3 col-xl mb-xl-0">
        <div class="card mb-3">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col">
                <h5 class="custom-card-title">
                  <i class="fas fa-bars"></i>
                  <span>ÜRÜN DETAYLARI</span>
                </h5>
              </div>
              <div class="col-auto pl-0" v-if="$route.params.id">
                <span class="text-muted font-14">{{ product.info.name }}</span>
              </div>
            </div>
            <hr />

            <!-- Brand & Model -->
            <div class="form-row">
              <!-- Brand -->
              <div class="col-12 mb-3 col-md mb-md-0">
                <div class="form-group">
                  <label for="brand" class="custom-label">Marka</label>
                  <select
                    name="brand"
                    id="brand"
                    class="custom-select"
                    v-model="product.detail.selected.brand"
                    v-on:change="onChangeBrand"
                  >
                    <option value="-1">Seçiniz</option>
                    <option
                      v-for="brand in product.brands"
                      v-bind:key="brand.id"
                      v-bind:value="brand.id"
                    >
                      {{ brand.name }}
                    </option>
                  </select>
                </div>
              </div>
              <!-- ./Brand -->

              <!-- Model -->
              <div class="col-12 col-md">
                <div class="form-group">
                  <label for="model" class="custom-label">Model</label>
                  <select
                    name="model"
                    id="model"
                    class="custom-select"
                    v-bind:disabled="!product.models.length"
                    v-model="product.detail.selected.model"
                  >
                    <option value="-1">Seçiniz</option>
                    <option
                      v-for="model in product.models"
                      v-bind:key="model.id"
                      v-bind:value="model.id"
                    >
                      {{ model.name }}
                    </option>
                  </select>
                </div>
              </div>
              <!-- ./Model -->
            </div>
            <!-- ./Brand & Model -->

            <!-- Stock & MPN & GTIN -->
            <div class="form-row">
              <!-- Mpn Code -->
              <div class="col-12 mb-3 col-md mb-md-0">
                <div class="form-group">
                  <label
                    for="mpn_code"
                    class="custom-label"
                    data-toggle="tooltip"
                    data-placement="right"
                    data-title="Üretici Parça Numarası"
                  >
                    <span>MPN Kodu</span>
                    <i
                      class="fas fa-question-circle ml-2"
                      v-tooltip.right="
                        'Üretici Parça Numarası (MPN), ticari ürünleri tanımlamak için kullanılan, ürün üzerine basılan, harf ve sayılardan oluşan koddur.'
                      "
                    ></i>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="mpn_code"
                    name="mpn_code"
                    placeholder="MPN Kodu"
                    v-model="product.detail.mpn"
                  />
                </div>
              </div>
              <!-- ./Mpn Code -->

              <!-- GTIN Code -->
              <!-- <div class="col-12 col-md mb-md-0">
                <div class="form-group">
                  <label
                    for="gtin_code"
                    class="custom-label"
                    data-toggle="tooltip"
                    data-placement="right"
                    data-title="Küresel Ticari Ürün Numarası"
                  >
                    <span>GTIN Kodu</span>
                    <i
                      class="fas fa-question-circle ml-2"
                      v-tooltip.right="
                        'Global Ticari Ürün Numarası (GTIN), ticari ürünleri tanımlamak için kullanılan, en az 8 en fazla 13 haneli kod numarasıdır.'
                      "
                    ></i>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="gtin_code"
                    name="gtin_code"
                    placeholder="GTIN Kodu"
                    v-model="product.detail.gtin"
                  />
                </div>
              </div> -->
              <!-- ./GTIN Code -->
              <!-- Package Barcode -->
              <div class="col-12 mb-3 col-md mb-md-0">
                <div class="form-group">
                  <label for="package_barcode" class="custom-label"
                    >Paket Barkodu (GTIN)</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="package_barcode"
                    name="packet_barcode"
                    placeholder="Paket Barkodu (GTIN)"
                    v-model="product.detail.packageBarcode"
                  />
                </div>
              </div>
              <!-- ./Package Barcode -->

              <!-- Parcel Barcode -->
              <div class="col-12 col-md">
                <div class="form-group">
                  <label for="parcel_code" class="custom-label"
                    >Koli Kodu</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="parcel_code"
                    name="parcel_code"
                    placeholder="Koli Kodu"
                    v-model="product.detail.parcelCode"
                  />
                </div>
              </div>
              <!-- ./Parcel Barcode -->
            </div>
            <!-- ./Stock & MPN & GTIN -->

            <div class="form-row">
              <!-- Milyem -->
              <div class="col-12 col-md">
                <div class="form-group">
                  <WeInput
                    label="Milyem"
                    v-bind:filter-number="true"
                    v-model="product.detail.milyem"
                  />
                </div>
              </div>
              <!-- ./Milyem -->

              <!-- Weight -->
              <div class="col-12 col-md">
                <div class="form-group">
                  <WeInput
                    label="Gram"
                    v-bind:filter-number="true"
                    v-model="product.detail.gram"
                  />
                </div>
              </div>
              <!-- ./Weight -->
              <div class="col-12 col-md">
                <div class="form-group">
                  <WeInput
                    prepend="%"
                    label="Kâr"
                    v-bind:filter-number="true"
                    v-model="product.detail.profit"
                  />
                </div>
              </div>
            </div>
            <div class="form-row"></div>
            <div class="form-row">
              <!-- Product API Code -->
              <div class="col-12 col-md">
                <div class="form-group">
                  <WeInput
                    name="workmanship-api-code"
                    label="Ürün API"
                    placeholder="Ürün API"
                    v-model="product.detail.productApiCode"
                  />
                </div>
              </div>
              <!-- ./Product API Code -->

              <!-- Workmanship Code -->
              <div class="col-12 col-md">
                <div class="form-group">
                  <WeInput
                    name="workmanship-api-code"
                    label="İşçilik Kodu"
                    placeholder="İşçilik Kodu"
                    v-model="product.detail.workmanshipApiCode"
                  />
                </div>
              </div>
              <!-- ./Workmanship Code -->

              <!-- Insurance Multiplier -->
              <div class="col-12 col-md">
                <div class="form-group">
                  <WeInput
                    label="Sigorta Çarpanı"
                    v-bind:filter-number="true"
                    v-model="product.detail.insuranceMultiplier"
                  />
                </div>
              </div>
              <!-- ./Insurance Multiplier -->
            </div>

            <!-- TAX & Buying Price & Warranty -->
            <div class="form-row">
              <!-- Buying Price -->
              <div class="col-12 mb-3 col-md mb-md-0">
                <WePriceInput
                  label="Alış Fiyatı"
                  name="purchase_price"
                  v-model="productPurchasePrice"
                  v-bind:append="product.currencySymbol"
                />
              </div>
              <!-- ./Buying Price -->

              <div class="col-12 col-md">
                <div class="form-group">
                  <WeInput
                    prepend="%"
                    label="İskonto"
                    v-bind:filter-number="true"
                    v-model="product.detail.reduction"
                  />
                </div>
              </div>
              <div class="col-12 col-md">
                <div class="form-group">
                  <label for="supplier" class="custom-label">Tedarikçi</label>
                  <select
                    name="supplier"
                    id="supplier"
                    class="custom-select"
                    v-model="product.detail.selected.supplier"
                    v-on:change="onChangeSupplier"
                  >
                    <option value="-1">Seçiniz</option>
                    <option
                      v-for="item in product.suppliers"
                      v-bind:key="item.id"
                      v-bind:value="item.id"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
              <!-- Warranty -->

              <!-- ./Warranty -->
            </div>
            <!-- ./TAX & Buying Price & Warranty -->
            <div class="form-row">
              <div class="col-12 col-md">
                <div class="form-group">
                  <WeInput
                    label="Garanti Süresi"
                    placeholder="Garanti Süresi"
                    v-model="product.detail.warranty"
                    append="Yıl"
                  />
                </div>
              </div>
              <div class="col-12 col-md">
                <!-- Preparation Time -->
                <WeInput
                  label="Hazırlanma Süresi"
                  append="Gün"
                  v-bind:filter-number="true"
                  v-bind:decimal="false"
                  v-model="product.detail.preparation_time"
                />
                <!-- ./Preparation Time -->
              </div>
            </div>
          </div>
        </div>

        <WeCard class="mb-3">
          <h5 class="custom-card-title">
            <i class="fas fa-palette"></i>
            <span>ÜRÜN TİPİ & ŞABLON</span>
          </h5>
          <hr />
          <div class="row">
            <div class="col-12 col-md">
              <label for="product_type" class="custom-label">Ürün Tipi</label>
              <v-select
                id="product_type"
                label="name"
                v-bind:reduce="(item) => item.id"
                v-bind:options="productType.types"
                v-model="product.detail.product_type"
              ></v-select>
            </div>
            <div class="col-12 col-md">
              <label for="product_type" class="custom-label"
                >Ürün Şablonu</label
              >
              <v-select
                id="product_template"
                label="name"
                v-bind:reduce="(item) => item.id"
                v-bind:options="productType.templates"
                v-model="product.detail.product_template"
              ></v-select>
            </div>
          </div>
        </WeCard>

        <!-- Cargo Information -->
        <WeCargoForm class="mb-3" v-model="product.detail.cargoPackage" />
        <!-- ./Cargo Information -->

        <div class="card mb-3">
          <div class="card-body">
            <h5 class="custom-card-title">
              <i class="fas fa-cube"></i>
              <span>ÜRÜN AÇIKLAMASI</span>
            </h5>
            <hr />

            <!-- ***** Widget ***** -->
            <WeMultipleDescription v-model="product.detail.descriptions" />

            <WeMetaData v-model="product.detail.meta" />
            <!-- ***** Widget ***** -->
          </div>
        </div>

        <!-- Attachment -->
        <WeProductAttachment v-model="product.detail.attachment" />
        <!-- ./Attachment -->
      </div>
      <!-- ./MAIN CONTENT -->

      <!-- ASIDE -->
      <div class="col-12 col-xl-5">
        <!-- Feature Section -->
        <WeFeature
          v-bind:feature-value="true"
          v-bind:language="product.lang"
          v-model="product.detail.features"
        />
        <!-- ./Feature Section -->

        <!-- Cargo Section -->
        <div class="mb-3" id="cargo-accordion">
          <div class="card">
            <!-- Accordion Header -->
            <div class="card-header p-0" id="cargo-head">
              <h2 class="mb-0">
                <button
                  class="btn btn-white btn-block text-left p-3 collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#cargo-collapse"
                  aria-expanded="true"
                  aria-controls="cargo-collapse"
                >
                  <h5 class="custom-card-title">
                    <i class="fas fa-truck"></i>
                    <span>KARGO FİRMALARI</span>
                    <i
                      class="
                        fas
                        fa-chevron-down
                        float-right
                        font-14
                        mt-1
                        text-muted
                      "
                    ></i>
                  </h5>
                </button>
              </h2>
            </div>
            <!-- Accordion Header -->

            <!-- Cargo Accordion Body -->
            <div
              id="cargo-collapse"
              class="collapse"
              aria-labelledby="cargo-head"
              data-parent="#cargo-search"
            >
              <div class="card-body">
                <!-- Search Cargo -->
                <input
                  type="text"
                  name="cargo_search"
                  id="cargo-search"
                  class="form-control"
                  placeholder="Kargo Ara"
                />
                <!-- ./Search Cargo -->
              </div>
            </div>

            <!-- ./Cargo Accordion Body -->
          </div>
        </div>
        <!-- ./Cargo Section -->

        <!-- Cross Products Section -->
        <WeProduct
          class="mb-3"
          title="Çapraz Ürünler"
          name="cross"
          icon="fas fa-random"
          v-bind:create="true"
          v-on:on-create="showModal"
          v-on:on-edit="onEdit"
          v-on:on-remove="onRemove"
          v-bind:columns="crossProductColumns"
          v-bind:actions="crossProductActions"
          v-bind:searchable="true"
          v-bind:limitable="false"
          v-bind:remove-self="false"
          v-bind:itemsPerPage="10"
          v-bind:collapsed="true"
          v-model="computedCrossProducts"
        />
        <!-- ./Cross Products Section -->

        <!-- Recommended Products Section -->
        <WeProduct
          class="mb-3"
          title="Önerilen Ürünler"
          name="recommended"
          v-bind:collapsed="true"
          icon="fas fa-thumbs-up"
          v-model="product.detail.recommendedProducts"
        />
        <!-- ./Recommended Products Section -->

        <!-- Matched Products Section -->
        <WeProduct
          class="mb-3"
          :title="$t('compatibleProducts')"
          name="compatible"
          icon="fas fa-sync"
          :productId="this.$route.params.id"
          :multipleSelect="true"
          v-bind:collapsed="true"
          v-model="product.detail.compatibleProducts"
        />

        <WeBrand
          v-for="(item, index) in brandList"
          v-bind:key="index"
          class="mb-3"
          v-bind:title="item.name"
          name="recommended"
          v-bind:type_id="item.id"
          v-bind:searchTitle="item.name + ' Ara'"
          v-bind:columns="brandTypeColmuns"
          icon="fa fa-tag"
          v-bind:multipleSelect="true"
          v-bind:collapsed="true"
          v-on:on-select="onSelectBrand"
          v-on:un-select="unSelectBrand"
          v-model="product.detail.brands"
        />

        <WeProduct
          class="mb-3"
          :title="$t('combineProducts')"
          name="compatible"
          icon="fas fa-bacon"
          :productId="this.$route.params.id"
          :multipleSelect="true"
          v-model="product.detail.combineProducts"
        />
        <!-- ./Matched Products Section -->
      </div>
      <!-- ./ASIDE -->
    </div>
    <!-- ./Tab Content -->

    <!-- Cross Product Modal -->
    <CrossProductModal
      v-model="computedCrossProducts"
      v-bind:show="show.modal"
      v-on:on-close="show.modal = false"
    />
    <!-- ./Cross Product Modal -->
  </div>
  <!-- ./Product Detail -->
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import CrossProductModal from "./components/CrossProductModal/Index";
const WeProductAttachment = () =>
  import("./components/ProductAttachment/Index.vue");
export default {
  name: "Detail",
  data() {
    return {
      show: {
        modal: false,
      },
      brandList: [],
      brandData: [],
      delay: 500,
      length: 3,
      crossProductColumns: [
        { name: "code", th: "Ürün Kodu", type: "string" },
        { name: "name", th: "Marka", type: "string" },
      ],
      brandTypeColmuns: [{ name: "name", th: "Ad", type: "string" }],
      crossProductActions: [
        {
          icon: "fas fa-pen fa-sm",
          class: "btn-outline-primary border-0 ml-2",
          action: "edit",
        },
        {
          icon: "fas fa-trash fa-sm",
          class: "btn-outline-danger border-0 ml-2",
          action: "remove",
        },
      ],
    };
  },
  components: {
    CrossProductModal,
    WeProductAttachment,
  },
  methods: {
    ...mapMutations("product", ["clearList"]),
    ...mapActions("product", ["search", "getModelList"]),
    ...mapActions("brandType", ["getList"]),
    onInput(e) {
      this.doSearch(e.target.value, this.delay, e.target.name);
    },
    doSearch(text, timeout, type) {
      let localTimeout = 0;
      if (timeout) {
        localTimeout = timeout;
      }

      if (text && text.trim().length) {
        if (text.trim().length >= this.length) {
          this.emitSearch(text, localTimeout, type);
        }
      } else {
        this.clearList(type);
      }
    },
    onSelectBrand(data) {
      data.forEach((element) => {
        if (
          this.product.detail.brands.filter((A) => A.id == element.id).length <=
          0
        ) {
          this.product.detail.brands.push(element);
        }
      });
    },
    unSelectBrand(id) {
      this.product.detail.brands = this.product.detail.brands.filter(function (
        obj
      ) {
        return obj.id !== id;
      });
    },
    emitSearch(text, timeout, type) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        clearTimeout(this.timer);
        this.search({
          type: type,
          query: text,
        });
      }, timeout);
    },
    showModal() {
      this.show.modal = true;
    },
    getBrandTypes() {
      this.getList({
        lang: this.tmpLang,
        onSuccess: (result) => {
          this.brandList = result.data.items;
        },
      });
    },
    onEdit(value) {
      const index = value.index;
      const row = value.row;

      this.crossproduct.item = {
        id: row.id,
        name: row.name,
        code: row.code,
        index: index,
        update: 1,
      };
      this.showModal();
    },
    onRemove(data) {
      const index = data.index;
      if (data.row.id && data.row.hasOwnProperty("deleted")) {
        const cpIndex = helper.arrayFind(
          this.computedCrossProducts,
          "id",
          data.row.id
        );

        if (cpIndex > -1) {
          const cp = this.computedCrossProducts[cpIndex];

          cp.deleted = 1;
          this.product.detail.removedCrossProducts.push(cp.id);
        }
      }
      this.computedCrossProducts.splice(index, 1);
    },
    onChangeBrand(e) {
      const productDetailSelected = this.product.detail.selected;
      productDetailSelected.brand = e.target.value;
      productDetailSelected.model = -1;
      this.getModelList();
    },
    onChangeSupplier(e) {
      const productDetailSelected = this.product.detail.selected;
      productDetailSelected.supplier = e.target.value == -1 ? null : e.target.value;
    },
  },
  computed: {
    ...mapState(["product", "crossproduct", "session", "productType"]),
    computedCrossProducts: {
      get() {
        return this.product.detail.crossProducts;
      },
      set(value) {
        this.product.detail.crossProducts = value;
      },
    },
    productPurchasePrice() {
      return this.product.detail.purchasePrice;
    },
  },
  mounted() {
    setTimeout(() => {
      this.getBrandTypes();
    }, 2000);
  },
};
</script>
