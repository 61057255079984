<template>
  <div class="tab-content">
    <Information ref="information" />
    <Detail />
    <Variation />
    <Marketplace v-if="marketplaceIntegration" ref="marketplace" />
  </div>
</template>

<script>
import Information from "./views/Information/Index";
import Detail from "./views/Detail/Index";
import Variation from "./views/Variation/Index";
const Marketplace = () => import("./views/Marketplace/Index");

import { mapState } from "vuex";
export default {
  name: "Content",
  components: {
    Information,
    Detail,
    Variation,
    Marketplace,
  },
  computed: {
    ...mapState({
      config: (state) => state.session.config,
    }),
    marketplaceIntegration() {
      return (
        this.config["site.marketplace_integration"] == "1" ||
        this.config["site.marketplace_integration"] == 1
      );
    },
  },
};
</script>
