<template>
  <!-- Product Variations -->
  <div
    class="tab-pane fade"
    id="product-variation"
    role="tabpanel"
    aria-labelledby="variation-tab"
  >
    <div v-if="variantSetting">
      <!-- Variant Features -->
      <WeFeature
        title="VARYASYON ÖZELLİKLERİ"
        v-model="product.variantFeatures"
        v-bind:feature-value="true"
        v-bind:multiple-value="true"
        v-bind:get-request="true"
        v-bind:feature-limit="3"
        v-bind:value-limit="20"
        v-bind:collapsed="false"
        v-on:value-update="updateAvailableVariants"
      />
      <!-- ./Variant Features -->

      <!-- Bulk Update -->
      <BulkVariantUpdate
        class="my-3"
        v-if="
          product.variantFeatures.length &&
          (product.availableVariants.length || productVariants.length)
        "
      />
      <!-- ./Bulk Update -->

      <!-- Current Variants -->
      <div class="card mb-3" v-if="productVariants.length">
        <div class="card-body">
          <div class="row align-items-center">
            <div class="col-12 col-lg">
              <h5 class="custom-card-title">
                <i class="fas fa-network-wired"></i>
                <span>MEVCUT VARYASYONLAR</span>
              </h5>
            </div>
            <div class="col-auto">
              <button
                class="btn btn-sm btn-outline-danger"
                v-on:click="removeAll"
              >
                <i class="fas fa-trash fa-sm"></i> Tümünü Kaldır
              </button>
            </div>
          </div>
          <hr />
          <WeTable
            v-bind:index="false"
            v-bind:data="productVariants"
            v-bind:columns="getApiColumns"
            v-bind:image-upload="true"
            v-bind:actions="actions"
            v-on:on-action="onAction"
            v-on:on-switch="onSwitch"
            v-on:image-removed="onRemoveImage"
            v-bind:size-limit-message="sizeLimitMessage"
          ></WeTable>
        </div>
      </div>
      <!-- /Current Variants -->

      <!-- Available Variants -->
      <div class="card mb-3">
        <div class="card-body">
          <h5 class="custom-card-title">
            <i class="fas fa-network-wired"></i>
            <span>KULLANILABİLECEK VARYASYONLAR</span>
          </h5>
          <hr />
          <div>
            <WeTable
              v-if="product.availableVariants.length"
              v-bind:index="false"
              v-bind:data="product.availableVariants"
              v-bind:columns="getColumns"
            ></WeTable>
            <div class="card alert-info" v-else>
              <div class="card-body">
                <i class="fas fa-info-circle"></i>
                <span class="ml-2" v-text="getNoFeatureText"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Available Variants -->
    </div>
    <div class="card alert-warning" v-else>
      <div class="card-body">
        <i class="fas fa-info-circle"></i>
        <span class="ml-2"
          >Varyasyon ekleyebilmek için
          <a href="/settings">Ayarlar</a> bölümünden aktif duruma
          getirmelisiniz.</span
        >
      </div>
    </div>
  </div>
  <!-- ./Product Variations -->
</template>

<script>
import { mapMutations, mapState } from "vuex";
import BulkVariantUpdate from "./views/BulkVariantUpdate/Index.vue";

export default {
  name: "Variation",
  components: {
    BulkVariantUpdate,
  },
  data() {
    return {
      sizeLimitMessage:
        "En Fazla 1024 KB boyutunda 1 Adet görsel yükleyebilirsiniz.",
      apiColumns: [
        { name: "media", th: "Görsel", type: "image_upload", width: "5%" },
        { name: "name", th: "Ad", type: "input", width:"30%" },
        { name: "sku_no", th: "Stok Kodu", type: "input" , width: "20%"},
        { name: "barcode", th: "Barkod", type: "input", width: "20%" },
        { name: "quantity", th: "Stok", type: "input", width: "10%" },
        { name: "price", th: "Fiyat", type: "price", width: "10%" },
        { name: "discount_price", th: "İndirimli Fiyatı", type: "price", width: "5%" },
        { name: "is_active", th: "Aktif", type: "boolean", width: "5%" },
      ],
      columns: [
        { name: "name", th: "Ad", type: "input", width: "30%" },
        { name: "sku_no", th: "Stok Kodu", type: "input", width: "20%" },
        { name: "barcode", th: "Barkod", type: "input", width: "20%" },
        { name: "quantity", th: "Stok", type: "input", width: "10%" },
        { name: "price", th: "Fiyat", type: "price", width: "10%" },
        { name: "discount_price", th: "İndirimli Fiyatı", type: "price", width: "5%"},
        { name: "is_active", th: "Aktif", type: "boolean", width: "5%" },
      ],
      actions: [
        {
          icon: "fas fa-trash",
          class: "btn-outline-danger ml-2",
          action: "remove",
        },
      ],
    };
  },
  methods: {
    ...mapMutations("product", ["setAvailableVariants"]),
    getCartesian(...a) {
      if (a && a.length) {
        return a.reduce((a, b) =>
          a.flatMap((d) =>
            b.flatMap((e) => {
              const data = [];
              e = helper.clone(e);
              d = helper.clone(d);

              let result = { ...e, ...d };
              result.features = [...e.features, ...d.features].reverse();
              result.sku_no = this.getSkuNo(result);
              if (this.checkAvailablity(result)) {
                data.push(result);
              }
              return data;
            })
          )
        );
      }
    },
    checkAvailablity(variant) {
      let available = true;

      const variantSku = this.productVariants.map((v) => v.sku_no);
      const values = this.getSelectedFeatureValues;
      const featureIdx = variant.features.flatMap((vf) => vf.id).sort();

      if (variantSku.includes(variant.sku_no)) {
        available = false;
      } else if (JSON.stringify(values) == JSON.stringify(featureIdx)) {
        available = false;
      } else if (this.checkVariantFeatures(featureIdx)) {
        available = false;
      } else {
        available = true;
      }

      return available;
    },
    checkVariantFeatures(ids) {
      let result = this.productVariants.filter((item) => {
        let features = item.features;

        if (features && features.length == ids.length) {
          const featureIdx = features.flatMap((f) => f.id).sort();
          return JSON.stringify(ids) == JSON.stringify(featureIdx);
        }
      });
      return result && result.length ? true : false;
    },
    getSkuNo(variant) {
      let valueCode = this.product.info.stockCode || "";
      for (let i = 0; i < variant.features.length; i++) {
        const value = variant.features[i];
        if (value.code) {
          valueCode += value.code;
        }
      }

      return valueCode;
    },
    onAction(data) {
      if (data.key == "remove") {
        this.onDelete(data.index);
      }
    },
    onSwitch(data) {
      switch (data.column) {
        case "is_active":
          const index = this.productVariants.findIndex(
            (A) => A.id == data.row.id
          );
          if (index >= 0) {
            this.productVariants[index]["is_active"] = data.is_active ? 1 : 0;
          }
          break;
      }
    },
    onRemoveImage(variant) {
      if (variant) {
        this.product.variantMedia.deleted.push(variant.id);
      }
    },
    onDelete(index) {
      this.$swal({
        title: "Silme İşlemini Onaylıyor musunuz ?",
        text: "Varyasyona ait bilgiler ve görseller silinecek.",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "İptal",
        confirmButtonText: "Sil",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          this.removeVariant(index);
        }
      });
    },
    removeAll() {
      this.$swal({
        title: "Silme İşlemini Onaylıyor musunuz ?",
        text: "Tüm Mevcut Varyasyona ait bilgiler ve görseller silinecek.",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "İptal",
        confirmButtonText: "Sil",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          this.product.removedVariants = helper.clone(this.product.variants);
          this.product.variants = [];
          this.updateAvailableVariants();
        }
      });
    },
    removeVariant(index) {
      let variant = this.productVariants[index];
      variant.sku_no = null;
      variant.name = null
      variant.price = null;
      variant.discount_price = null;
      variant.quantity = 0;
      variant.barcode = null;
      variant.is_active = true;
      variant.features = null;
      variant.media.files = [];
      variant.exist = false;
      this.product.removedVariants.push(variant);
      this.productVariants.splice(index, 1);
      this.updateAvailableVariants();
    },
    updateAvailableVariants() {
      let result = [];
      const variantFeatures = this.variantFeatures;

      for (let i = 0; i < variantFeatures.length; i++) {
        const feature = variantFeatures[i];

        let valueData = [];
        if (feature.value && feature.value.length) {
          for (let j = 0; j < feature.value.length; j++) {
            const featureValue = feature.value[j];

            let variant = {};
            variant.features = [];
            variant[feature.feature_id] = featureValue.name;
            variant.features.push(featureValue);
            variant.sku_no = this.getSkuNo(variant);
            variant.name = `${this.product?.info?.name} - ${featureValue.name}`
            variant.barcode = "";
            variant.is_active = false;
            variant.price = helper.clone(this.defaultPrice);
            variant.discount_price = helper.clone(this.defaultDiscountedPrice);
            if (this.checkAvailablity(variant)) {
              valueData.push(variant);
            }
          }
        }
        result = [...result, valueData];
      }
      let variants = this.getCartesian(...result);
      this.setAvailableVariants(variants);
    },
  },
  computed: {
    ...mapState(["product", "session"]),
    defaultPrice() {
      const priceData = this.product.info.normalPrice;
      return {
        price: priceData.unmask || priceData.price,
        unmask: priceData.unmask || priceData.price,
      };
    },
    defaultDiscountedPrice() {
      const priceData = this.product.info.discountedPrice;
      return {
        price: priceData.unmask || priceData.price,
        unmask: priceData.unmask || priceData.price,
      };
    },
    currencySetting() {
      const currencies = this.product.currencies;
      let selected =
        this.product.info.selected.currency == -1
          ? session.config["site.currency_code"]
          : this.product.info.selected.currency;
      let currency = localization.locale.code;

      let index = helper.arrayFind(currencies, "id", selected);
      if (index !== -1) {
        currency = currencies[index];
      }

      let data = {
        locale: currency,
        currency: null,
        precision: 2,
        allowNegative: false,
        autoDecimalMode: false,
      };
      return data;
    },
    variantSetting() {
      return this.session.config["site.product_variants"] == "1";
    },
    getSelectedFeatureValues() {
      let values = this.product.detail.features.filter((f) => f.id); // f.id -> feature value id
      return values
        .flatMap((v) => {
          return v.id;
        })
        .sort();
    },
    getNoFeatureText() {
      let text = "Varyasyon ekleyebilmek için Özellik ";

      if (this.variantFeatures.length) {
        text += "seçmelisiniz.";
      } else {
        text += "oluşturmalısınız.";
      }

      return text;
    },
    variantFeatures: {
      get() {
        return this.product.variantFeatures;
      },
      set(value) {
        this.product.variantFeatures = value;
      },
    },
    getApiColumns() {
      const columns = this.apiColumns;

      let features = this.variantFeatures.map((feature) => {
        let column = {};
        column.name = feature.feature_id;
        column.th = feature.feature_name;
        column.type = "string";
        return column;
      });

      let result = [...features, ...columns];

      return result;
    },
    getColumns() {
      const columns = this.columns.map((item) => {
        const currencySymbol = this.product.currencySymbol;
        if (item.name == "price") {
          item.th =
            "Fiyat (" +
            this.product.info.normalPrice.price +
            " " +
            currencySymbol +
            ")";
          item.setOnMount = false;
        } else if (item.name == "discount_price") {
          item.th =
            "İndirimli Fiyat (" +
            this.product.info.discountedPrice.price +
            " " +
            currencySymbol +
            ")";
          item.setOnMount = false;
        }
        return item;
      });

      let features = this.variantFeatures.map((feature) => {
        let column = {};
        column.name = feature.feature_id;
        column.th = feature.feature_name;
        column.type = "string";
        return column;
      });

      let result = [...features, ...columns];

      return result;
    },
    productVariants() {
      return this.product.variants;
    },
  },
  mounted() {
    this.updateAvailableVariants();
  },
  watch: {
    variantFeatures: function () {
      this.updateAvailableVariants();
    },
    "product.info.normalPrice.unmask": function () {
      this.updateAvailableVariants();
    },
    "product.info.discountedPrice.unmask": function () {
      this.updateAvailableVariants();
    },
    "product.info.stockCode": function () {
      this.updateAvailableVariants();
    },
  },
};
</script>
